import Blog from '../components/Blog'
import { graphql } from 'gatsby'

export default Blog


export const pageQuery = graphql`
    {
        allEnglishPostsRecent: allMdx(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                isFuture: { eq: false }
                fields: { slug: { regex: "/^/blog/" } }
                frontmatter: { date: { ne: null },  locale: { eq: "en" } }
            }
            limit: 4
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        allEnglishPostsPopular: allMdx(
            sort: { order: DESC, fields: [fields___pageViews] }
            filter: {
                isFuture: { eq: false }
                fields: { slug: { regex: "/^/blog/" } }
                frontmatter: { date: { ne: null },  locale: { eq: "en" } }
            }
            limit: 4
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        allArabicPostsRecent: allMdx(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                isFuture: { eq: false }
                fields: { slug: { regex: "/^/blog/" } }
                frontmatter: { date: { ne: null },  locale: { eq: "ar" } }
            }
            limit: 4
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        allArabicPostsPopular: allMdx(
            sort: { order: DESC, fields: [fields___pageViews] }
            filter: {
                isFuture: { eq: false }
                fields: { slug: { regex: "/^/blog/" } }
                frontmatter: { date: { ne: null },  locale: { eq: "ar" } }
            }
            limit: 4
        ) {
            edges {
                node {
                    ...BlogFragment
                }
            }
        }
        layoutData: allMdx(filter: { fields: { slug: { regex: "/^/layout/" } } }) {
            nodes {
                parent {
                    ... on GoogleDocs {
                        id
                        layout {
                            brand
                            fonts {
                                fontFamily
                                src {
                                    url
                                    format
                                }
                                fontWeight
                                fontStyle
                                fontDisplay
                            }
                            variables {
                                key
                                value
                            }
                            sidebars {
                                name
                                items {
                                  name
                                  url
                                  enable
                                  badge {
                                    title
                                    className
                                    title_ar
                                  }
                                  children {
                                    name
                                    url
                                    enable
                                    badge {
                                      title
                                      className
                                      title_ar
                                    }
                                    children {
                                      name
                                      url
                                      enable
                                      badge {
                                        title
                                        className
                                        title_ar
                                      }
                                      children {
                                        name
                                        url
                                        enable
                                        badge {
                                          title
                                          className
                                          title_ar
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              sidebars_ar {
                                name
                                items {
                                  name
                                  url
                                  enable
                                  badge {
                                    title
                                    className
                                    title_ar
                                  }
                                  children {
                                    name
                                    url
                                    enable
                                    badge {
                                      title
                                      className
                                      title_ar
                                    }
                                    children {
                                      name
                                      url
                                      enable
                                      badge {
                                        title
                                        className
                                        title_ar
                                      }
                                      children {
                                        name
                                        url
                                        enable
                                        badge {
                                          title
                                          className
                                          title_ar
                                        }
                                      }
                                    }
                                  }
                                }
                            }
                            variables {
                                key
                                value
                            }
                            logoFileName
                        }
                    }
                }
            }
        }
        categories: allMdx(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
                isFuture: { eq: false }
                fields: { slug: { regex: "/^/blog/" } }
                frontmatter: { date: { ne: null } }
            }
        ) {
            group(field: frontmatter___category, limit: 2) {
                category: fieldValue
                edges {
                    node {
                        ...BlogFragment
                    }
                }
            }
        }
    }
`
